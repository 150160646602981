import styled from 'styled-components'
import { Icon } from '../../../../old/icon'
import { Box, Text } from '../../../core-components'
import { ThemeColors, ThemeShadows } from '../../../theme'

export type BaseButtonVariant = 'major' | 'moderate' | 'minor' | 'cautionHigh' | 'cautionLow'

export const BaseButtonIcon = styled(Icon)``
export const BaseButtonEndIcon = styled(Icon)``

/** Internal design system component for sharing styles between Button and OptionButton */
export const BaseButtonBox = styled(Box)<{
  UNSAFE_hexColor?: string
  endIconColor?: ThemeColors
  endIconFocusColor?: ThemeColors
  endIconHoverColor?: ThemeColors
  focusBg?: ThemeColors
  focusColor?: ThemeColors
  focusShadow?: ThemeShadows
  hoverBg: ThemeColors
  hoverColor?: ThemeColors
  iconColor: ThemeColors
  iconFocusColor?: ThemeColors
  iconHoverColor: ThemeColors
}>`
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  ${({ UNSAFE_hexColor }) => (UNSAFE_hexColor ? `background: ${UNSAFE_hexColor};` : '')}

  ${BaseButtonIcon} {
    color: ${({ iconColor, theme }) => theme.colors[iconColor]};
  }

  ${BaseButtonEndIcon} {
    color: ${({ endIconColor, theme }) => endIconColor && theme.colors[endIconColor]};
  }

  :hover,
  :disabled,
  &[aria-disabled='true'] {
    ::after {
      box-shadow: none;
    }
  }

  :hover,
  [data-x='y'] /* we need anything here because otherwise storybook-addon-pseudostates doesn't work in OptionButton dark mode variant major (no idea why) */ {
    background: ${({ hoverBg, theme }) => theme.colors[hoverBg]};
    ${({ UNSAFE_hexColor }) => (UNSAFE_hexColor ? `background: ${UNSAFE_hexColor}CC;` : '')}

    ${Text} {
      color: ${({ hoverColor, theme }) => hoverColor && theme.colors[hoverColor]};
    }

    ${BaseButtonIcon} {
      color: ${({ iconHoverColor, theme }) => theme.colors[iconHoverColor]};
    }

    ${BaseButtonEndIcon} {
      color: ${({ endIconHoverColor, theme }) =>
        endIconHoverColor && theme.colors[endIconHoverColor]};
    }
  }

  :focus {
    outline: none;
    // We can't move it to ::after shadow hack (see Box) because not all buttons have shadows
    box-shadow: ${({ theme, focusShadow }) => focusShadow && theme.shadows[focusShadow]} !important;
    background: ${({ focusBg, theme }) => focusBg && theme.colors[focusBg]};

    ::after {
      box-shadow: ${({ focusShadow }) => focusShadow && 'none'};
    }

    ${Text} {
      color: ${({ focusColor, theme }) => focusColor && theme.colors[focusColor]};
    }

    ${BaseButtonIcon} {
      color: ${({ iconFocusColor, theme }) => iconFocusColor && theme.colors[iconFocusColor]};
    }

    ${BaseButtonEndIcon} {
      color: ${({ endIconFocusColor, theme }) =>
        endIconFocusColor && theme.colors[endIconFocusColor]};
    }
  }

  :disabled,
  &[aria-disabled='true'] {
    cursor: default;
    background: ${({ theme }) => theme.colors['bg/disabled']};

    ${Text} {
      color: ${({ theme }) => theme.colors['text/action-disabled']};
    }

    ${BaseButtonIcon}, ${BaseButtonEndIcon} {
      color: ${({ theme }) => theme.colors['icon/disabled']};
    }
  }
`

export const baseButtonVariantProps: Record<
  BaseButtonVariant,
  {
    bg: ThemeColors
    border?: ThemeShadows
    color: ThemeColors
    hoverBg: ThemeColors
    hoverColor: ThemeColors
    iconColor: ThemeColors
    iconHoverColor: ThemeColors
  }
> = {
  major: {
    bg: 'bg/button-major',
    color: 'text/action-oncolor',
    hoverBg: 'bg/button-hovered-major',
    hoverColor: 'text/action-oncolor',
    iconColor: 'icon/on-color',
    iconHoverColor: 'icon/on-color',
  },
  moderate: {
    bg: 'bg/button-moderate',
    color: 'text/action-moderate',
    hoverBg: 'bg/button-hovered-moderate',
    hoverColor: 'text/action-moderate',
    iconColor: 'icon/action-moderate',
    iconHoverColor: 'icon/action-moderate',
  },
  minor: {
    bg: 'bg/button-minor',
    border: 'border/on-major',
    color: 'text/action-minor',
    hoverBg: 'bg/button-hovered-minor',
    hoverColor: 'text/action-minor',
    iconColor: 'icon/action-minor',
    iconHoverColor: 'icon/action-minor',
  },
  cautionHigh: {
    bg: 'bg/button-caution',
    color: 'text/action-major-oncolor',
    hoverBg: 'bg/button-hovered-caution',
    hoverColor: 'text/action-oncolor',
    iconColor: 'icon/on-color',
    iconHoverColor: 'icon/on-color',
  },
  cautionLow: {
    bg: 'bg/button-minor',
    border: 'border/on-major',
    color: 'text/action-caution',
    hoverBg: 'bg/button-hovered-caution',
    hoverColor: 'text/action-oncolor',
    iconColor: 'icon/action-caution',
    iconHoverColor: 'icon/on-color',
  },
}

export const baseButtonProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  m: 0,
  border: 'none',
  borderRadius: 10,
  px: 4,
  py: 3,
  w: 'fit-content',
} as const
