import React, { DOMAttributes, useEffect, useRef, useState } from 'react'
import { AriaToastProps, useToast } from '@react-aria/toast'
import { ToastState } from '@react-stately/toast'
import { SvgRemove } from '@chilipiper/icons/src/design-system'
import styled, { keyframes } from 'styled-components'
import { FocusableElement } from '@react-types/shared'
import { AriaButtonProps } from 'react-aria'
import { IconButton } from '../../action'
import { Box, Flex, Text } from '../../../core-components'
import { darkTheme } from '../../../theme'
import { ThemeProviderClean } from '../../../../ThemeProviderClean'
import { AlertIcon } from '../../data/alert-icon/AlertIcon'
import { AlertBanner } from '../alert-banner/AlertBanner'
import { AlertBannerType, ToastType } from './types'

export interface ToastProps extends AriaToastProps<ToastType | AlertBannerType> {
  state: ToastState<ToastType | AlertBannerType>
}

export const Toast = ({ state, toast, ...ariaProps }: ToastProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { toastProps, titleProps, closeButtonProps } = useToast({ toast, ...ariaProps }, state, ref)
  const { content } = toast

  const [height, setHeight] = useState<string>('0')
  useEffect(() => {
    if (toast.animation === 'exiting') {
      setHeight('0')
    } else {
      setHeight(ref.current?.getBoundingClientRect().height + 'px')
    }
  }, [toast.animation])

  return (
    <AnimatedWrapperBox h={height}>
      <AnimatedFlex
        {...toastProps}
        ref={ref}
        w={content.INTERNAL_type === 'alert' ? '432px' : '386px'}
        data-animation={toast.animation}
        onAnimationEnd={() => {
          if (toast.animation === 'exiting') {
            state.remove(toast.key)
          }
        }}
        data-test-id={toast.content['data-test-id']}
      >
        {content.INTERNAL_type === 'toast' ? (
          <ToastContent
            variant={content.variant}
            title={content.title}
            titleProps={titleProps}
            closeButtonProps={closeButtonProps}
          />
        ) : (
          <AlertBanner
            type='floating'
            variant={content.variant}
            title={content.title}
            description={content.description}
            actions={content.actions}
            isDismissable={content.isDismissable}
            onClose={() => state.close(toast.key)}
            titleProps={titleProps}
            closeButtonProps={closeButtonProps}
          />
        )}
      </AnimatedFlex>
    </AnimatedWrapperBox>
  )
}

const ToastContent = ({
  title,
  titleProps,
  closeButtonProps,
  variant,
}: {
  closeButtonProps: AriaButtonProps<'button'>
  title: string
  titleProps: DOMAttributes<FocusableElement>
  variant: ToastType['variant']
}) => {
  return (
    <Box bg='bg/toast' borderRadius={8} w='100%'>
      <ThemeProviderClean variation={darkTheme}>
        <Flex px={6} py={4} gap={3}>
          <AlertIcon size={4} variant={variant} mt={1} />
          <StyledText textStyle='multiline-body' color='text/body-major-oncolor' {...titleProps}>
            {title}
          </StyledText>
          <IconButton
            icon={SvgRemove}
            variant='ghost'
            size={6}
            label='Close'
            hasTooltip={false}
            as='button'
            ariaProps={closeButtonProps}
          />
        </Flex>
      </ThemeProviderClean>
    </Box>
  )
}

const slideIn = keyframes`
  from {
    transform: translateY(-200%)
  }
  to {
    transform: translateY(0)
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`

const AnimatedWrapperBox = styled(Box)`
  transition: height 0.2s ease-in-out;
  pointer-events: auto;
`

const AnimatedFlex = styled(Flex)`
  &[data-animation='entering'] {
    animation: ${slideIn} 0.2s ease-in-out;
  }

  &[data-animation='queued'] {
    animation: ${fadeIn} 0.5s ease-in-out;
  }

  &[data-animation='exiting'] {
    animation: ${fadeOut} 0.2s ease-in-out;
  }
`

const StyledText = styled(Text)`
  align-self: center;
  flex-grow: 1;
`
