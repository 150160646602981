import { SvgExclamationMark, SvgWarning } from '@chilipiper/icons/src/design-system'
import isString from 'lodash/isString'
import React, { ComponentPropsWithoutRef, ComponentType } from 'react'
import { Button } from '../../new/components/action'
import { Loader } from '../../new/components/feedback'
import { Box, Divider, Flex, Text } from '../../new/core-components'
import { Card } from '../card'
import { Icon } from '../icon'
import { Modal } from './Modal'
import { useModalFlowControllers } from './modal-provider/useModalFlowControllers'
import { BaseModalLayoutProps } from './types'

/**
 * @deprecated - Use ConfirmationModal, ConfigurationModal or Modal from
 *   `@chilipiper/design-system/src/new` instead
 */
export const BaseModalLayout = ({
  header,
  children,
  cancelText,
  confirmText,
  confirmIsDisabled,
  isLoading,
  variant = 'neutral',
  cardProps,
  withDivider = true,
  isOpen,
  cancelButtonIsHidden,
  zIndex = 11,
  ...rest
}: BaseModalLayoutProps & { zIndex?: number }) => {
  const { onClose, onCancelClick, onConfirmClick, confirmActionIsRunning } =
    useModalFlowControllers(rest)

  const confirmationButtonIsHidden = !onConfirmClick && !confirmText
  const shouldHideButtons = isLoading || (cancelButtonIsHidden && confirmationButtonIsHidden)

  const ICONS: { [key in typeof variant]?: ComponentType } = {
    warning: SvgWarning,
  }
  const ICON_COLORS: { [key in typeof variant]?: ComponentPropsWithoutRef<typeof Icon>['color'] } =
    {
      error: 'icon/error',
      warning: 'icon/warning',
    }

  return (
    <Modal isOpen={isOpen} shouldCloseOnEsc onRequestClose={onClose} overlayStyles={{ zIndex }}>
      <Card minWidth={23} maxWidth={25} p={0} data-test-id='ConfirmationModal' {...cardProps}>
        {isLoading ? (
          <Flex justifyContent='center' py={10}>
            <Loader />
          </Flex>
        ) : (
          <Flex flexDirection='column'>
            <Box>
              {header && (
                <Flex px={7} pt={7} pb={5} alignItems='center'>
                  {variant !== 'neutral' && (
                    <Icon
                      mr={3}
                      icon={ICONS[variant] ?? SvgExclamationMark}
                      color={ICON_COLORS[variant] ?? 'icon/inform'}
                    />
                  )}
                  {isString(header) ? (
                    <Text textStyle='page-heading' m={0}>
                      {header}
                    </Text>
                  ) : (
                    header
                  )}
                </Flex>
              )}
              <Box mx={7} mb={7}>
                <Text textStyle='item-unselected' color='text/body-moderate'>
                  {children}
                </Text>
              </Box>
            </Box>
            {!shouldHideButtons && (
              <Flex flexDirection='column'>
                {withDivider && <Divider />}
                <Flex
                  pt={withDivider ? 4 : 0}
                  pb={4}
                  px={6}
                  justifyContent={confirmationButtonIsHidden ? 'end' : 'space-between'}
                >
                  {!cancelButtonIsHidden ? (
                    <Button
                      variant='minor'
                      onClick={onCancelClick}
                      data-test-id='ConfirmationModal-cancel-btn'
                    >
                      {cancelText || 'Cancel'}
                    </Button>
                  ) : (
                    <Box />
                  )}
                  {!confirmationButtonIsHidden && (
                    <Button
                      variant={
                        variant === 'error' || variant === 'warning' ? 'cautionHigh' : 'major'
                      }
                      onClick={onConfirmClick}
                      data-test-id='ConfirmationModal-confirm-btn'
                      isDisabled={confirmIsDisabled}
                      isLoading={confirmActionIsRunning}
                    >
                      {confirmText || 'Confirm'}
                    </Button>
                  )}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Card>
    </Modal>
  )
}
