import React from 'react'
import { createContextWithHook } from '@chilipiper/utils'
import { Icon } from '../../../../old/icon'
import { ThemeColors } from '../../../theme'
import { Avatar } from '../../wayfinding/avatar/Avatar'
import { AvatarGroup } from '../../wayfinding/avatar-group/AvatarGroup'

type User = React.ComponentPropsWithoutRef<typeof AvatarGroup>['users'][number]

type IconProps = {
  icon: React.ComponentType
  id?: never
  image?: never
  name?: never
  users?: never
  variant: 'icon'
}

type AvatarGroupProps = {
  icon?: never
  id?: never
  image?: never
  name?: never
  users: User[]
  variant: 'avatarGroup'
}

type AvatarProps = {
  icon?: never
  id: User['id']
  image?: User['image']
  name: User['name']
  users?: never
  variant: 'avatar'
}

type Props = IconProps | AvatarGroupProps | AvatarProps

const { StartContentBaseProvider, useStartContentContext } = createContextWithHook<{
  iconColor?: ThemeColors
}>()('StartContent')

export const StartContentProvider = StartContentBaseProvider

export const StartContent = (props: Props) => {
  const { iconColor } = useStartContentContext()

  if (props.variant === 'icon') {
    return <Icon aria-hidden w={4} h={4} color={iconColor} icon={props.icon} flexShrink={0} />
  }
  if (props.variant === 'avatar') {
    return <Avatar size={4} id={props.id} name={props.name} image={props.image} />
  }
  return <AvatarGroup size={4} users={props.users} />
}
