import { createContextWithHook } from '@chilipiper/utils'
import React, { ComponentPropsWithoutRef } from 'react'
import { AriaPositionProps } from 'react-aria'
import { Icon } from '../../../../old/icon/Icon'
import { Flex } from '../../../core-components'
import { ThemeColors } from '../../../theme'
import { Badge } from '../../feedback/badge/Badge'
import { IconToggletip, IconTooltip } from '../../feedback/icon-tooltip/IconTooltip'

type IconProps = {
  badges?: never
  content?: never
  icon: React.ComponentType
  iconTooltipVariant?: never
  isDisabled?: never
  placement?: never
  variant: 'icon'
}

type BadgesProps = {
  badges: Pick<React.ComponentPropsWithoutRef<typeof Badge>, 'variant' | 'label'>[]
  content?: never
  icon?: never
  iconTooltipVariant?: never
  isDisabled?: never
  placement?: never
  variant: 'badges'
}

type IconTooltipProps = {
  badges?: never
  content: React.ComponentPropsWithoutRef<typeof IconTooltip>['content']
  icon?: never
  iconTooltipVariant?: React.ComponentPropsWithoutRef<typeof IconTooltip>['variant']
  isDisabled?: boolean
  placement?: AriaPositionProps['placement']
  variant: 'iconTooltip'
}

type IconToggletipProps = {
  badges?: never
  content: React.ComponentPropsWithoutRef<typeof IconToggletip>['content']
  icon?: never
  iconTooltipVariant?: React.ComponentPropsWithoutRef<typeof IconToggletip>['variant']
  isDisabled?: boolean
  placement?: AriaPositionProps['placement']
  variant: 'iconToggletip'
}

type Props = IconProps | BadgesProps | IconTooltipProps | IconToggletipProps

const { EndContentBaseProvider, useEndContentContext } = createContextWithHook<{
  iconColor?: ThemeColors
  size?: ComponentPropsWithoutRef<typeof IconTooltip>['size']
}>()('EndContent')

export const EndContentProvider = EndContentBaseProvider

// needed to avoid error with ReferenceError: Cannot access 'EndContent' before initialization, we need to look for a better way in the future
// eslint-disable-next-line react/function-component-definition
export function EndContent({ iconTooltipVariant, ...props }: Props) {
  const { iconColor, size = 4 } = useEndContentContext()

  if (props.variant === 'icon') {
    return <Icon aria-hidden w={size} h={size} color={iconColor} icon={props.icon} />
  }
  if (props.variant === 'badges') {
    return (
      <Flex gap={2}>
        {props.badges.map((badge, index) => (
          <Badge key={index} variant={badge.variant} label={badge.label} />
        ))}
      </Flex>
    )
  }
  if (props.variant === 'iconTooltip') {
    return (
      <IconTooltip size={size} {...props} content={props.content} variant={iconTooltipVariant} />
    )
  }
  return (
    <IconToggletip size={size} {...props} content={props.content} variant={iconTooltipVariant} />
  )
}
