import React from 'react'
import type { ButtonAriaProps } from '../../../types'
import { BaseActionBarButton } from '../BaseActionBarButton'
import { useButtonProps } from '../../hooks'

export type ActionBarIconButtonPublicProps = {
  ariaProps?: ButtonAriaProps
  'data-id'?: string
  'data-test-id'?: string
  icon: React.ComponentType
  isDisabled?: boolean
  onClick: () => void
}

type InternalProps = {
  INTERNAL_isFocusVisible?: boolean
}

type Props = InternalProps & ActionBarIconButtonPublicProps

export const ActionBarIconButton = ({
  isDisabled,
  'data-id': dataId,
  INTERNAL_isFocusVisible,
  'data-test-id': dataTestId,
  icon,
  onClick,
  ariaProps,
}: Props) => {
  const { ref, isFocusVisible, buttonProps } = useButtonProps({
    onClick,
    type: 'button',
    INTERNAL_isFocusVisible,
    isDisabled,
    ...ariaProps,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...rest } = buttonProps

  return (
    <BaseActionBarButton
      variant='minor'
      wrapper={{ as: 'button', ref }}
      isFocusVisible={isFocusVisible}
      data-id={dataId}
      data-test-id={dataTestId}
      startIcon={icon}
      {...rest}
    />
  )
}
