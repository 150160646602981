import { createContextWithHook } from '@chilipiper/utils'
import { ModalFlowContextActions, ModalFlowContextStateData } from '../types'

export type PromiseResolutionMethods = {
  reject?: (value: unknown) => void
  resolve?: (value: unknown) => void
}

export type ModalFlowContextState = ModalFlowContextStateData &
  PromiseResolutionMethods &
  ModalFlowContextActions

const context = createContextWithHook<ModalFlowContextState>()('ModalFlow')

/**
 * @deprecated - Use ConfirmationModal, ConfigurationModal or Modal from
 *   `@chilipiper/design-system/src/new` instead
 */
export const ModalFlowBaseProvider = context.ModalFlowBaseProvider
/**
 * @deprecated - Use ConfirmationModal, ConfigurationModal or Modal from
 *   `@chilipiper/design-system/src/new` instead
 */
export const useModalFlowContext = context.useModalFlowContext
