import React, { Children } from 'react'
import { typeValidator } from '@chilipiper/utils'
import { ButtonAriaProps } from './types'

export const isMac = () => window && /^Mac/i.test(window.navigator.platform)
export const flattenChildren: typeof Children.toArray = (...args) => {
  const childrenArray = Children.toArray(...args)
  return childrenArray.reduce((flatChildren: ReturnType<typeof Children.toArray>, child) => {
    if (typeValidator(React.Fragment)(child)) {
      return flatChildren.concat(flattenChildren(child.props.children))
    }
    flatChildren.push(child)
    return flatChildren
  }, [])
}

export const splitButtonAriaProps = (props: ButtonAriaProps | undefined) => {
  const {
    buttonRef,
    onPointerEnter,
    onPointerLeave,
    onMouseLeave,
    onMouseEnter,
    onTouchStart,
    ...reactAriaProps
  } = props ?? {}
  return {
    buttonRef,
    domProps: { onPointerEnter, onPointerLeave, onMouseLeave, onMouseEnter, onTouchStart },
    reactAriaProps,
  }
}
