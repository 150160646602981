import styled from 'styled-components'
import React, { ComponentProps } from 'react'
import { Icon } from '../../../../old/icon'
import { Box, Text } from '../../../core-components'
import { ThemeColors, ThemeShadows } from '../../../theme'
import { baseLinkButtonProps, baseLinkButtonVariantProps } from '../base-button/BaseLinkButton'
import { Spinner } from '../../feedback/spinner/Spinner'
import { IconTooltip } from '../../feedback/icon-tooltip/IconTooltip'
import { useActionBarUsageCheck } from './ActionBarContext'

export type BaseActionBarButtonVariant = 'major' | 'minor' | 'caution'

const StyledStartIcon = styled(Icon)``
const StyledEndIcon = styled(Icon)``
const StyledBox = styled(Box)<{
  activeBorder?: ThemeShadows
  expandedBg: ThemeColors
  focusBg?: ThemeColors
  focusShadow?: ThemeShadows
  hoverBg: ThemeColors
}>`
  position: relative;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  text-decoration: none;

  &[aria-expanded='true'] ${StyledEndIcon} {
    transform: rotate(180deg);
  }

  :hover {
    background: ${({ theme, hoverBg }) => theme.colors[hoverBg]};
  }

  :focus {
    outline: none;
    background: ${({ focusBg, theme }) => focusBg && theme.colors[focusBg]};

    ::after {
      content: '';
      position: absolute;
      inset: 0;
      box-shadow: ${({ theme, focusShadow }) => focusShadow && theme.shadows[focusShadow]};
    }
  }

  :disabled,
  &[aria-disabled='true'] {
    cursor: default;
    background: ${({ theme }) => theme.colors['bg/disabled']};

    ${Text} {
      color: ${({ theme }) => theme.colors['text/action-disabled']};
    }

    ${StyledStartIcon}, ${StyledEndIcon} {
      color: ${({ theme }) => theme.colors['icon/disabled']};
    }

    ${Box}::after {
      box-shadow: none;
    }
  }

  &[aria-expanded='true'] {
    position: relative;
    background: ${({ theme, expandedBg }) => theme.colors[expandedBg]};
    box-shadow: ${({ theme, activeBorder }) => activeBorder && theme.shadows[activeBorder]};
  }
`
const StyledSeparator = styled(Box)<{ isReducedSize?: boolean }>`
  transition: height 0.15s ease-in-out;
  height: calc(100% - 24px);

  ${StyledBox}:hover &,
  ${StyledBox}[disabled] &,
  ${StyledBox}[aria-disabled='true'] & {
    height: 100%;
  }
`

const variantProps: Record<
  BaseActionBarButtonVariant,
  {
    expandedBg: ThemeColors
    hoverBg: ThemeColors
  }
> = {
  major: {
    hoverBg: 'bg/action-bar-button-hovered-major',
    expandedBg: 'bg/action-bar-button-focused-major',
  },
  minor: {
    hoverBg: 'bg/action-bar-button-hovered-minor',
    expandedBg: 'bg/action-bar-button-focused-minor',
  },
  caution: {
    hoverBg: 'bg/action-bar-button-hovered-caution',
    expandedBg: 'bg/action-bar-button-focused-caution',
  },
}

type Props = ComponentProps<typeof Box> & {
  INTERNAL_hasNextSibling?: boolean
  INTERNAL_isOnlyChild?: boolean
  activeBorder?: ThemeShadows
  children?: string | string[]
  'data-id'?: string
  'data-test-id'?: string
  endIcon?: React.ComponentType
  isFocusVisible?: boolean
  isLoading?: boolean
  startIcon?: React.ComponentType
  tooltip?: string
  variant: BaseActionBarButtonVariant
  wrapper:
    | { as: 'a'; ref: React.RefObject<HTMLAnchorElement> }
    | { as: 'button'; ref: React.RefObject<HTMLButtonElement> }
}

export const BaseActionBarButton = ({
  INTERNAL_hasNextSibling,
  INTERNAL_isOnlyChild,
  children,
  'data-id': dataId,
  'data-test-id': dataTestId,
  isFocusVisible,
  startIcon,
  endIcon,
  variant,
  wrapper,
  activeBorder,
  isLoading,
  tooltip,
  ...otherProps
}: Props) => {
  useActionBarUsageCheck() // Make sure ActionBarButton is used inside ActionBar

  const { color, underlineShadow, iconColor } = baseLinkButtonVariantProps[variant]
  const { hoverBg, expandedBg } = variantProps[variant]
  return (
    <StyledBox
      as={wrapper.as}
      display='flex'
      gap={2}
      alignItems='start'
      px={6}
      py={4}
      m={0}
      w={INTERNAL_isOnlyChild ? '100%' : undefined}
      border='none'
      bg='transparent'
      position='relative'
      ref={wrapper.ref}
      data-id={dataId}
      data-test-id={dataTestId}
      activeBorder={activeBorder}
      hoverBg={hoverBg}
      focusBg={isFocusVisible ? hoverBg : undefined}
      expandedBg={expandedBg}
      focusShadow={isFocusVisible ? 'border/focus-indicator' : undefined}
      {...otherProps}
    >
      <StartContent isLoading={isLoading} startIcon={startIcon} iconColor={iconColor} />
      {children && (
        <Box {...baseLinkButtonProps} pb='2px' boxShadow={underlineShadow}>
          <Text display='block' textStyle='button' color={color}>
            {children}
          </Text>
        </Box>
      )}
      {tooltip && <IconTooltip content={tooltip} variant='inform' size={4} />}
      {endIcon && (
        <StyledEndIcon flexShrink={0} w={4} h={4} color='icon/indicator' icon={endIcon} />
      )}
      {INTERNAL_hasNextSibling && !isFocusVisible && (
        <StyledSeparator w='1px' h='100%' bg='bg/cell' position='absolute' bottom={0} right={0} />
      )}
    </StyledBox>
  )
}

const StartContent = ({
  isLoading,
  startIcon,
  iconColor,
}: {
  iconColor?: ThemeColors
  isLoading?: boolean
  startIcon?: React.ComponentType
}) => {
  if (isLoading) {
    return <Spinner />
  }
  if (startIcon) {
    return <StyledStartIcon flexShrink={0} icon={startIcon} color={iconColor} w={4} h={4} />
  }
  return null
}
