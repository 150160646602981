import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { SystemProps } from '../../new/theme'
import { Box } from '../../new/core-components/box'

type Props = SystemProps & HTMLAttributes<any>

export type CardProps = Props

export const Card = styled(Box)``

Card.defaultProps = {
  boxShadow: 'border/on-major',
  p: 4,
  borderRadius: 8,
  bg: 'bg/major',
}
