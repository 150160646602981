import { SvgCheckCircleFilled } from '@chilipiper/icons/src/design-system'
import React from 'react'
import { Icon } from '../../../../old/icon'
import { Text } from '../../../core-components'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { ThemeColors } from '../../../theme'
import { Spinner } from '../../feedback/spinner/Spinner'
import { ButtonAriaProps, OmitInternalProperties, PositioningProps } from '../../types'
import {
  BaseButtonBox,
  BaseButtonIcon,
  BaseButtonVariant,
  baseButtonProps,
  baseButtonVariantProps,
} from '../base-button/BaseButton'
import { useAnchorProps, useButtonProps } from '../hooks'
import { AnchorOnlyProps, ButtonOnlyProps } from '../types'

type CommonProps = PositioningProps & {
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_nativeOnClick?: (event: React.MouseEvent) => void
  UNSAFE_hexColor?: string
  ariaProps?: ButtonAriaProps
  children: string | string[]
  'data-id'?: string
  'data-test-id'?: string
  endIcon?: React.ComponentType
  isDisabled?: boolean
  isFullWidth?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  startIcon?: React.ComponentType
  variant: BaseButtonVariant
}

export type ButtonProps = CommonProps & ButtonOnlyProps

export type AnchorProps = CommonProps & AnchorOnlyProps

type Props = ButtonProps | AnchorProps

export const ButtonInternal = ({ children, ...props }: Props) => {
  if (props.as === 'a') {
    return <ButtonAsAnchor {...props}>{children}</ButtonAsAnchor>
  } else {
    return <ButtonAsButton {...props}>{children}</ButtonAsButton>
  }
}

type PublicProps = OmitInternalProperties<ButtonProps> | OmitInternalProperties<AnchorProps>

export const ButtonPublic = (props: PublicProps) => <ButtonInternal {...props} />

const ButtonAsButton = ({
  isDisabled,
  isLoading,
  onClick,
  type,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  variant,
  children,
  isFullWidth,
  startIcon,
  endIcon,
  isSuccess,
  'data-id': dataId,
  'data-test-id': dataTestId,
  as: _as, // eslint-disable-line @typescript-eslint/no-unused-vars
  ariaProps,
  UNSAFE_hexColor,
  ...positioningProps
}: ButtonProps) => {
  validatePositioningProps(positioningProps, 'Button')

  const { ref, isFocusVisible, buttonProps } = useButtonProps({
    onClick,
    type,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    isDisabled: isDisabled || isLoading || isSuccess,
    ...ariaProps,
  })
  const commonProps = getCommonProps(variant, isFocusVisible, isFullWidth)
  const { color } = baseButtonVariantProps[variant]

  return (
    <BaseButtonBox
      as='button'
      ref={ref}
      data-id={dataId}
      data-test-id={dataTestId}
      {...commonProps}
      {...positioningProps}
      {...buttonProps}
      UNSAFE_hexColor={UNSAFE_hexColor}
    >
      <Content
        isSuccess={isSuccess}
        isLoading={isLoading}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {children}
      </Content>
    </BaseButtonBox>
  )
}

const ButtonAsAnchor = ({
  isDisabled,
  isLoading,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  variant,
  children,
  isFullWidth,
  rel,
  target,
  href,
  isSuccess,
  'data-id': dataId,
  'data-test-id': dataTestId,
  onClick,
  startIcon,
  endIcon,
  as: _as, // eslint-disable-line @typescript-eslint/no-unused-vars
  ariaProps,
  ...positioningProps
}: AnchorProps) => {
  validatePositioningProps(positioningProps, 'Button')

  const { anchorProps, isFocusVisible, ref } = useAnchorProps({
    ...ariaProps,
    onClick,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    isDisabled: isDisabled || isLoading || isSuccess,
  })
  const commonProps = getCommonProps(variant, isFocusVisible, isFullWidth)
  const { color } = baseButtonVariantProps[variant]

  return (
    <BaseButtonBox
      as='a'
      ref={ref}
      href={href}
      target={target}
      rel={rel}
      data-id={dataId}
      data-test-id={dataTestId}
      {...commonProps}
      {...positioningProps}
      {...anchorProps}
    >
      <Content
        isSuccess={isSuccess}
        isLoading={isLoading}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {children}
      </Content>
    </BaseButtonBox>
  )
}

const Content = ({
  color,
  children,
  isLoading,
  endIcon,
  startIcon,
  isSuccess,
}: Pick<Props, 'isLoading' | 'startIcon' | 'endIcon' | 'children' | 'isSuccess'> & {
  color: ThemeColors
}) => {
  return (
    <>
      <StartIcon startIcon={startIcon} isSuccess={isSuccess} isLoading={isLoading} />
      <Text color={color} textStyle='button' py='1px' whiteSpace='nowrap'>
        {children}
      </Text>
      {endIcon && <BaseButtonIcon icon={endIcon} ml={2} w={4} h={4} />}
    </>
  )
}

const StartIcon = ({
  isLoading,
  isSuccess,
  startIcon,
}: Pick<Props, 'isLoading' | 'isSuccess' | 'startIcon'>) => {
  if (isLoading) {
    return <Spinner mr={2} />
  }
  if (isSuccess) {
    return <Icon icon={SvgCheckCircleFilled} color='icon/success' mr={2} w={4} h={4} />
  }
  if (startIcon) {
    return <BaseButtonIcon icon={startIcon} mr={2} w={4} h={4} />
  }
  return null
}

const getCommonProps = (
  variant: Props['variant'],
  isFocusVisible: boolean,
  isFullWidth?: boolean
) => {
  const { bg, border, hoverColor, hoverBg, iconColor, iconHoverColor } =
    baseButtonVariantProps[variant]

  return {
    ...baseButtonProps,
    // To keep consistent with button - https://html.spec.whatwg.org/multipage/rendering.html#button-layout
    // > If the computed value of 'inline-size' is 'auto', then the used value is the fit-content inline size
    w: isFullWidth ? '100%' : 'fit-content',
    bg,
    boxShadow: border,
    hoverColor,
    hoverBg,
    iconColor,
    iconHoverColor,
    iconFocusColor: isFocusVisible ? iconHoverColor : undefined,
    focusColor: isFocusVisible ? hoverColor : undefined,
    focusBg: isFocusVisible ? hoverBg : undefined,
    focusShadow: isFocusVisible ? 'border/focus-indicator' : undefined,
  } as const
}
