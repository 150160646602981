import styled from 'styled-components'
import { Box } from '../box'

export const Divider = styled(Box)``

Divider.defaultProps = {
  bg: 'bg/row',
  height: '1px',
}

export const VerticalDivider = styled(Box)``

VerticalDivider.defaultProps = {
  bg: 'bg/row',
  height: '100%',
  width: '1px',
  flexShrink: 0,
}
