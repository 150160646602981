import React, { HTMLProps, useRef } from 'react'
import { mergeProps, useButton } from 'react-aria'
import styled from 'styled-components'
import { Box, BoxType } from '../box'
import { ButtonAriaProps } from '../../components/types'
import { useFocusVisible } from '../../components/hooks'
import { splitButtonAriaProps } from '../../components/helpers'

const StyledBox = styled(Box)<{ isFocusVisible: boolean }>`
  cursor: pointer;

  :disabled {
    cursor: auto;
  }

  :focus {
    outline: none;
    box-shadow: ${({ isFocusVisible, theme }) =>
      isFocusVisible && theme.shadows['border/focus-indicator']};
  }
`
export const UnstyledButton = ({
  onClick,
  disabled,
  ...props
}: BoxType &
  Omit<HTMLProps<HTMLButtonElement>, 'onClick'> & {
    ariaProps?: ButtonAriaProps
    onClick?: ButtonAriaProps['onPress']
  }) => {
  const { buttonRef, domProps, reactAriaProps } = splitButtonAriaProps(props.ariaProps)
  const fallbackRef = useRef<HTMLButtonElement>(null)
  const { isFocusVisible, focusProps } = useFocusVisible()
  const ref = buttonRef ?? fallbackRef
  const { buttonProps } = useButton(
    { onPress: onClick, isDisabled: disabled, ...reactAriaProps },
    ref
  )

  return (
    <StyledBox
      as='button'
      ref={ref}
      type='button'
      p={0}
      m={0}
      borderRadius='none'
      border='none'
      bg='transparent'
      textAlign='start'
      display='block'
      isFocusVisible={isFocusVisible}
      {...mergeProps(props, buttonProps, focusProps, domProps)}
    />
  )
}
