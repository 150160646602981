import React, { useId, useState } from 'react'
import styled from 'styled-components'
import invariant from 'tiny-invariant'
import { mergeProps } from 'react-aria'
import { SvgHiddenOptions } from '@chilipiper/icons/src/design-system'
import { typeValidator } from '@chilipiper/utils'
import { Icon } from '../../../../../old/icon'
import { Popover } from '../../../../core-components/popover/Popover'
import { UnstyledButton } from '../../../../core-components/unstyled-button/UnstyledButton'
import { ScreenReaderOnly } from '../../../../core-components'
import { ThemeColors, ThemeShadows } from '../../../../theme'
import { useFocusVisible } from '../../../hooks'
import { Action } from '../../action-list'

export type ActionBarMoreButtonPublicProps = {
  children: React.ReactNode
  'data-id'?: string
  'data-test-id'?: string
}

type InternalProps = {
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_isOpen?: boolean
}

type Props = InternalProps & ActionBarMoreButtonPublicProps

export const ActionBarMoreButton = ({
  children,
  'data-id': dataId,
  INTERNAL_isFocusVisible,
  INTERNAL_isOpen,
  'data-test-id': dataTestId,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const triggerId = useId()
  const { focusProps, isFocusVisible } = useFocusVisible(INTERNAL_isFocusVisible)
  // check children before opening popover
  invariant(
    React.Children.toArray(children).every(typeValidator(Action.Item)),
    'All ActionBar.MoreButton children must be of type Action.Item'
  )

  const handleAction = () => setIsOpen(false)

  return (
    <Popover
      ariaProps={{ type: 'menu' }}
      placement='bottom start'
      offset={8}
      isOpen={INTERNAL_isOpen || isOpen}
      onOpenChange={setIsOpen}
      trigger={ariaProps => {
        return (
          <MoreButton
            data-id={dataId}
            data-test-id={dataTestId}
            focusBg={isFocusVisible ? 'bg/action-bar-button-hovered-minor' : undefined}
            focusShadow={isFocusVisible ? 'border/focus-indicator' : undefined}
            display='flex'
            px={6}
            py={4}
            ariaProps={mergeProps(ariaProps, focusProps, { id: triggerId })}
          >
            <ScreenReaderOnly>More actions</ScreenReaderOnly>
            <Icon icon={SvgHiddenOptions} w={4} h={4} color='icon/action-minor' />
          </MoreButton>
        )
      }}
    >
      <Action.List ariaProps={{ 'aria-labelledby': triggerId }} onAction={handleAction}>
        {children}
      </Action.List>
    </Popover>
  )
}

const MoreButton = styled(UnstyledButton)<{ focusBg?: ThemeColors; focusShadow?: ThemeShadows }>`
  position: relative;

  :hover {
    background: ${({ theme }) => theme.colors['bg/action-bar-button-hovered-minor']};
  }

  :focus {
    outline: none;
    background: ${({ focusBg, theme }) => focusBg && theme.colors[focusBg]};

    ::after {
      content: '';
      position: absolute;
      inset: 0;
      box-shadow: ${({ theme, focusShadow }) => focusShadow && theme.shadows[focusShadow]};
    }
  }

  &[aria-expanded='true'] {
    background: ${({ theme }) => theme.colors['bg/action-bar-button-focused-minor']};
    box-shadow: ${({ theme }) => theme.shadows['border/action-focused-minor']};
  }
`
