import { TLengthStyledSystem } from 'styled-system'
import CSS from 'csstype'
import { css } from 'styled-components'
import {
  ThemeFontFamily,
  ThemeFontSizes,
  ThemeFontWeights,
  ThemeLineHeights,
} from '../../theme/types'
import { TextStyle } from './types'

export type TextStyleValue = {
  fontFamily?: ThemeFontFamily | CSS.Property.FontFamily
  fontSize?: ThemeFontSizes | CSS.Property.FontSize<TLengthStyledSystem>
  fontWeight?: ThemeFontWeights | CSS.Property.FontWeight
  lineHeight?: ThemeLineHeights | CSS.Property.LineHeight<TLengthStyledSystem>
  textTransform?: CSS.Property.TextTransform
}

type StrictTextStyleValue = TextStyleValue & {
  fontFamily?: ThemeFontFamily
  fontSize?: ThemeFontSizes
  fontWeight?: ThemeFontWeights
  lineHeight?: ThemeLineHeights
  textTransform?: CSS.Property.TextTransform
}

export const getTextStyleCss = (textStyle: TextStyle) => {
  const { fontWeight, fontSize, fontFamily, lineHeight, textTransform } =
    textPropsByTextStyle[textStyle]
  return css`
    font-family: ${({ theme }) => (fontFamily ? theme.fonts[fontFamily] : undefined)};
    font-size: ${({ theme }) => (fontSize ? theme.fontSizes[fontSize] : undefined)};
    font-weight: ${({ theme }) => (fontWeight ? theme.fontWeights[fontWeight] : undefined)};
    line-height: ${({ theme }) => (lineHeight ? theme.lineHeights[lineHeight] : undefined)};
    text-transform: ${textTransform};
  `
}

export const textPropsByTextStyle: Record<TextStyle, StrictTextStyleValue> = {
  'page-section-heading': {
    fontSize: 14,
    fontWeight: 600,
  },
  'page-heading': {
    fontSize: 16,
    fontWeight: 600,
  },
  'multiline-body-tight': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 4,
  },
  'multiline-body': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 8,
  },
  'form-value-multiline': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 8,
  },
  'form-value': {
    fontSize: 14,
    fontWeight: 500,
  },
  'form-value-emphasis': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'none',
  },
  'monoline-body-emphasis': {
    fontSize: 14,
    fontWeight: 600,
  },
  'monoline-body': {
    fontSize: 14,
    fontWeight: 500,
  },
  'multiline-button': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 8,
  },
  cell: {
    fontSize: 14,
    fontWeight: 500,
  },
  'modal-heading': {
    fontSize: 16,
    fontWeight: 600,
  },
  'list-group-heading': {
    fontSize: 12,
    fontWeight: 600,
  },
  'item-unselected': {
    fontSize: 14,
    fontWeight: 500,
  },
  'item-selected': {
    fontSize: 14,
    fontWeight: 600,
  },
  'form-label': {
    fontSize: 12,
    fontWeight: 600,
  },
  'form-label-inline': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'none',
  },
  'detail-emphasis': {
    fontSize: 12,
    fontWeight: 600,
  },
  'detail-caps': {
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  detail: {
    fontSize: 12,
    lineHeight: 2,
    fontWeight: 500,
  },
  'code-inline': {
    fontFamily: 'monospace',
    fontSize: 14,
    fontWeight: 400,
  },
  'code-block': {
    fontFamily: 'monospace',
    lineHeight: 4,
    fontSize: 14,
    fontWeight: 400,
  },
  'cell-heading': {
    fontSize: 14,
    fontWeight: 600,
  },
  'cell-multiline': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 8,
  },
  button: {
    fontSize: 14,
    fontWeight: 600,
  },
  'main-heading': {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 'none',
  },
  'main-heading-big': {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 4,
  },
  item: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'none',
  },
}
