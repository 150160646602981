import { useEffect } from 'react'
import invariant from 'tiny-invariant'
import { isMac } from './isMac'

export type Hotkey = {
  key: string
  modifier?: ['metaKey', 'shiftKey'] | ['ctrlKey', 'shiftKey'] | 'metaKey' | 'shiftKey' | 'ctrlKey'
}
export const useHotkey = (hotkey: Hotkey | undefined, onHotkeyClick: () => unknown) => {
  useEffect(() => {
    if (!hotkey) {
      return
    }
    if (hotkey.modifier === 'shiftKey' || hotkey.modifier?.[1] === 'shiftKey') {
      invariant(
        new RegExp(/^\p{L}$/u).test(hotkey.key),
        'Shift modifier can only be used with letters'
      )
    }

    const handleKeyDown = async (e: KeyboardEvent) => {
      if (
        hotkey.key.toLowerCase() === e.key.toLowerCase() &&
        hasCorrectModifier(e, hotkey.modifier)
      ) {
        onHotkeyClick()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [hotkey, onHotkeyClick])
}

const hasCorrectModifier = (e: KeyboardEvent, modifier: Hotkey['modifier']) => {
  const numberUsedModifiers = [e.metaKey, e.shiftKey, e.ctrlKey, e.altKey].filter(Boolean).length
  if (!modifier) {
    return numberUsedModifiers === 0
  }
  if (Array.isArray(modifier)) {
    if (numberUsedModifiers !== 2) {
      return false
    }
    return e[possiblyMapCmdToCtrl(modifier[0])] && e[modifier[1]]
  }
  return e[possiblyMapCmdToCtrl(modifier)] && numberUsedModifiers === 1
}

const possiblyMapCmdToCtrl = (modifier: 'metaKey' | 'shiftKey' | 'ctrlKey') => {
  if (modifier === 'metaKey' && !isMac()) {
    return 'ctrlKey' as const
  }
  return modifier
}
