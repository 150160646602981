import { palette } from './palette'

export const contextualBgPalette = {
  'bg/accent': palette['neutral-dark-100'],
  'bg/action-bar-form-focused': palette['teal-dark-12'],
  'bg/action-bar': palette['neutral-dark-2'],
  'bg/column-resizer': palette['purple-dark-24'],
  'bg/action-list': palette['white-100'],
  'bg/button-major': palette['purple-dark-100'],
  'bg/button-hovered-major': palette['purple-dark-80'],
  'bg/button-focused-major': palette['purple-dark-8'],
  'bg/button-moderate': palette['neutral-dark-8'],
  'bg/button-hovered-moderate': palette['neutral-dark-12'],
  'bg/button-focused-moderate': palette['neutral-dark-8'],
  'bg/button-minor': palette['white-100'],
  'bg/button-hovered-minor': palette['neutral-dark-12'],
  'bg/button-focused-minor': palette['neutral-dark-8'],
  'bg/button-caution': palette['red-dark-100'],
  'bg/button-hovered-caution': palette['red-dark-80'],
  'bg/button-focused-caution': palette['red-dark-8'],
  'bg/action-bar-button-major': palette['purple-dark-100'],
  'bg/action-bar-button-hovered-major': palette['purple-dark-12'],
  'bg/action-bar-button-focused-major': palette['purple-dark-8'],
  'bg/action-bar-button-moderate': palette['neutral-dark-8'],
  'bg/action-bar-button-hovered-moderate': palette['neutral-dark-12'],
  'bg/action-bar-button-focused-moderate': palette['neutral-dark-8'],
  'bg/action-bar-button-minor': palette['white-100'],
  'bg/action-bar-button-hovered-minor': palette['neutral-dark-12'],
  'bg/action-bar-button-focused-minor': palette['neutral-dark-8'],
  'bg/action-bar-button-caution': palette['red-dark-100'],
  'bg/action-bar-button-hovered-caution': palette['red-dark-12'],
  'bg/action-bar-button-focused-caution': palette['red-dark-8'],
  'bg/action-inform': palette['blue-dark-100'],
  'bg/admin-center-layout': 'linear-gradient(264.43deg, #4d4b8e 0%, #1c1b31 99.99%, #1b1a2e 100%)',
  'bg/background-navigation': 'hsl(242, 29%, 17%, 1)',
  'bg/button-light': palette['white-100'],
  'bg/cell-action-minor-focused-last-child': palette['neutral-dark-8'],
  'bg/cell-action-minor-focused': palette['neutral-dark-8'],
  'bg/cell-focused-last-child': palette['teal-dark-8'],
  'bg/cell-focused': palette['teal-dark-8'],
  'bg/cell-hover': palette['neutral-dark-4'],
  'bg/cell-oncolor': palette['neutral-light-8'],
  'bg/cell': palette['neutral-dark-8'],
  'bg/chat-block': `linear-gradient(0deg, ${palette['neutral-dark-4']}, ${palette['neutral-dark-4']}), ${palette['white-100']}`,
  'bg/chat-bubble': `linear-gradient(0deg, ${palette['neutral-dark-8']}, ${palette['neutral-dark-8']}), ${palette['white-100']}`,
  'bg/chat-disabled': palette['neutral-dark-4'],
  'bg/chat-form-item-error-focused': palette['red-dark-12'],
  'bg/chat-form-item-error': palette['red-dark-8'],
  'bg/chat-form-item-focused': palette['neutral-dark-8'],
  'bg/form-value-focused': palette['teal-dark-12'],
  'bg/chat-form-item-hover': palette['neutral-dark-8'],
  'bg/chat-form-item-unfocused': palette['white-100'],
  'bg/chat-reply-button-focused': palette['neutral-light-48'],
  'bg/chat-reply-button': palette['neutral-light-24'],
  'bg/chat-scrollbar-control': palette['neutral-dark-12'],
  'bg/chat-transparent': `linear-gradient(0deg, ${palette['white-100']} 0%, transparent 43%)`,
  'bg/container-highlight': palette['neutral-dark-4'],
  'bg/container': palette['white-100'],
  'bg/context-switcher': 'hsl(244, 29%, 19%, 1)',
  'bg/date-picker-item-hovered': palette['purple-dark-20'],
  'bg/date-picker-item-selected': palette['purple-dark-100'],
  'bg/date-picker-range': palette['purple-dark-12'],
  'bg/dim-overlay': palette['neutral-dark-80'],
  'bg/disabled': palette['neutral-dark-4'],
  'bg/dots-loader': palette['orange-dark-100'],
  'bg/drop-zone': palette['blue-dark-100'],
  'bg/error-input-select': palette['red-dark-8'],
  'bg/feedback-caution': palette['red-dark-8'],
  'bg/feedback-inform': palette['blue-dark-8'],
  'bg/feedback-neutral': palette['neutral-dark-8'],
  'bg/feedback-success': palette['green-dark-8'],
  'bg/admin-center-integration-success': palette['green-dark-16'],
  'bg/feedback-warning': palette['yellow-light-8'],
  'bg/card-item-warning': palette['yellow-light-12'],
  'bg/asset-pill': palette['white-100'],
  'bg/asset-pill-hover': palette['neutral-dark-8'],
  'bg/fire-modal-gradient':
    'linear-gradient(266.9deg, #7A365F 1.85%, #662F50 43.79%, #4D3A71 98.64%)',
  'bg/form-item-focused-error': palette['red-dark-12'],
  'bg/form-item-error': palette['red-dark-8'],
  'bg/form-item-focused': palette['purple-dark-8'],
  'bg/form-item-hover': palette['neutral-dark-8'],
  'bg/form-item-unfocused': palette['white-100'],
  'bg/list-item-hover': palette['neutral-dark-4'],
  'bg/list-item-caution-hover': palette['red-dark-12'],
  'bg/list-item': 'transparent',
  'bg/list-item-selected': palette['white-100'],
  'bg/list-item-unselected': `linear-gradient(0deg, ${palette['neutral-dark-2']}, ${palette['neutral-dark-2']}), ${palette['white-100']}`,
  'bg/list-item-rounded-selected': palette['neutral-dark-8'],
  'bg/list-item-rounded-hovered': palette['neutral-dark-4'],
  'bg/main-nav-item-hovered': palette['purple-light-12'],
  'bg/main-nav-item-selected': palette['purple-light-24'],
  'bg/modal': `linear-gradient(0deg, ${palette['neutral-dark-2']}, ${palette['neutral-dark-2']}), ${palette['white-100']}`,
  'bg/major': palette['white-100'],
  'bg/moderate': `linear-gradient(0deg, ${palette['neutral-light-12']}, ${palette['neutral-light-12']}), ${palette['white-100']}`,
  'bg/navigation-selected': `radial-gradient(400% 240% at -170% 75%, rgb(153, 0, 102) 10%, rgb(255, 82, 51) 50%, rgb(255, 149, 0) 90%)`,
  'bg/no-data': `linear-gradient(0deg, ${palette['neutral-light-12']}, ${palette['neutral-light-12']}), ${palette['white-100']}`,
  'bg/page': `linear-gradient(0deg, ${palette['neutral-dark-2']}, ${palette['neutral-dark-2']}), ${palette['white-100']}`,
  'bg/popover': palette['white-100'],
  'bg/row-oncolor': palette['neutral-light-12'],
  'bg/row-selected': palette['white-100'],
  'bg/row-selected-table': palette['purple-light-12'], // we still don't have the correct name for this, the name is the same as the above color in figma
  'bg/row-unselected': `linear-gradient(0deg, ${palette['neutral-light-16']}, ${palette['neutral-light-16']}), ${palette['white-100']}`,
  'bg/row': palette['neutral-dark-8'],
  'bg/scrollbar-background': palette['neutral-dark-4'],
  'bg/scrollbar-control': palette['neutral-dark-12'],
  'bg/select-indicator-major-disabled': palette['purple-dark-48'],
  'bg/select-indicator-major': palette['purple-dark-100'],
  'bg/select-indicator-minor-disabled': palette['neutral-dark-48'],
  'bg/select-indicator-minor': palette['neutral-dark-100'],
  'bg/select-indicator-moderate-disabled': palette['teal-dark-48'],
  'bg/select-indicator-moderate': palette['teal-dark-100'],
  'bg/select-item-major': palette['purple-dark-100'],
  'bg/select-item-disabled-major': palette['purple-dark-48'],
  'bg/select-item-minor': palette['neutral-dark-100'],
  'bg/select-item-hover-minor': palette['neutral-dark-8'],
  'bg/select-item-minor-disabled': palette['neutral-dark-48'],
  'bg/dropdown': `linear-gradient(0deg, ${palette['neutral-light-12']}, ${palette['neutral-light-12']}), ${palette['white-100']}`,
  'bg/skeleton-background': palette['neutral-dark-8'],
  'bg/skeleton-flicker': palette['neutral-dark-20'],
  'bg/spinner-loader': palette['neutral-dark-100'],
  'bg/switch-off': palette['neutral-dark-20'],
  'bg/switch-on-disabled': palette['purple-dark-48'],
  'bg/switch-on-disabled-status': palette['green-dark-48'],
  'bg/switch-on-status': palette['green-dark-100'],
  'bg/switch-on': palette['purple-dark-100'],
  'bg/tab-item-selected': palette['orange-dark-100'],
  'bg/toast': palette['neutral-dark-100'],
  'bg/tree-item-line': palette['neutral-dark-24'],
  'bg/tree-item-rounded-selected': palette['neutral-dark-12'],
  'bg/background-gradient-email': `linear-gradient(263.23deg, #733559 17.44%, #4C396D 84.52%);`,
  'bg/chart-bar': 'linear-gradient(90deg, #543DFF 0.88%, rgba(84, 61, 255, 0.68) 105.1%)',
  'bg/chart-bar-inactive':
    'linear-gradient(90deg, rgba(21, 29, 35, 0.64) 3.61%, rgba(21, 29, 35, 0.00) 117.86%)',

  'bg/calendar-item-purple':
    'linear-gradient(0deg, rgba(165, 153, 255, 0.24) 0%, rgba(165, 153, 255, 0.24) 100%), #FFF',
  'bg/calendar-item-yellow':
    'linear-gradient(0deg, rgba(255, 159, 26, 0.20) 0%, rgba(255, 159, 26, 0.20) 100%), #FFF',
  'bg/calendar-item-gray':
    'linear-gradient(0deg, rgba(21, 29, 35, 0.08) 0%, rgba(21, 29, 35, 0.08) 100%), #FFF',
  'bg/calendar-item-green':
    'linear-gradient(0deg, rgba(0, 189, 142, 0.20) 0%, rgba(0, 189, 142, 0.20) 100%), #FFF',
  'bg/calendar-item-blue':
    'linear-gradient(0deg, rgba(41, 159, 255, 0.2), rgba(41, 159, 255, 0.2)), #FFF',
  'bg/calendar-item-focused-yellow': palette['yellow-light-100'],
  'bg/calendar-item-focused-blue': palette['blue-dark-100'],
  'bg/path-outline-hovered': palette['purple-dark-2'],
  'bg/chart-item-purple': `linear-gradient(270deg, hsla(0, 0%, 100%, 0.00) 0%, hsla(0, 0%, 100%, 0.48) 100%), ${palette['purple-dark-80']}`,
  'bg/chart-item-yellow': `linear-gradient(270deg, hsla(0, 0%, 100%, 0.00) 0%, hsla(0, 0%, 100%, 0.48) 100%), ${palette['yellow-light-80']}`,
  'bg/chart-item-green': `linear-gradient(270deg, hsla(0, 0%, 100%, 0.00) 0%, hsla(0, 0%, 100%, 0.48) 100%), ${palette['green-light-80']}`,
  'bg/chart-item-red': `linear-gradient(270deg, hsla(0, 0%, 100%, 0.00) 0%, hsla(0, 0%, 100%, 0.48) 100%), ${palette['red-light-80']}`,
  'bg/canvas': `linear-gradient(0deg, ${palette['neutral-dark-4']}, ${palette['neutral-dark-4']}), ${palette['white-100']}`,
}
