import React from 'react'
import { useMenuItem } from 'react-aria'
import { TreeState } from 'react-stately'
import styled from 'styled-components'
import invariant from 'tiny-invariant'
import { Hotkey, typeValidator, useHotkey } from '@chilipiper/utils'
import { Icon } from '../../../../old/icon'
import { Box, Flex, Text } from '../../../core-components'
import { ThemeColors } from '../../../theme'
import { EndContent, EndContentProvider } from '../../data'
import { useActionListContext } from './helpers'
import { getHotkeyString } from './hotkey'

type Variant = 'regular' | 'caution'

type PublicProps = {
  children: string | string[]
  'data-id'?: string
  'data-test-id'?: string
  description?: string
  endContent?: React.ReactNode
  hotkey?: Hotkey
  isDisabled?: boolean
  onAction?: () => void
  startIcon?: React.ComponentType
  variant?: Variant
}

export const ActionItemInternal = ({
  item,
  state,
  hotkey,
  startIcon,
  children,
  'data-id': dataId,
  'data-test-id': dataTestId,
  description,
  variant = 'regular',
  onAction,
  endContent,
}: {
  item: NonNullable<ReturnType<TreeState<object>['collection']['getItem']>>
  onAction: (key: React.Key) => void
  state: TreeState<object>
} & Omit<PublicProps, 'onAction'>) => {
  useActionListContext() // Check if used inside ActionList
  invariant(
    endContent === undefined || typeValidator(EndContent)(endContent),
    'endContent must be a Action.EndContent component or undefined'
  )
  const ref = React.useRef<HTMLLIElement>(null)
  const { menuItemProps, isDisabled, descriptionProps, labelProps, keyboardShortcutProps } =
    useMenuItem({ key: item.key, onAction }, state, ref)
  const { color, iconColor, bg } = getColors(isDisabled, variant)

  useHotkey(hotkey, () => {
    if (!isDisabled) {
      onAction(item.key)
    }
  })

  return (
    <StyledBox
      as='li'
      px={5}
      bg={bg}
      variant={variant}
      data-id={dataId}
      data-test-id={dataTestId}
      ref={ref}
      {...menuItemProps}
    >
      <Box boxShadow='border/list-item' py={4}>
        <Flex justifyContent='space-between' alignItems='center'>
          {startIcon && <Icon icon={startIcon} mr={2} color={iconColor} />}
          <Text
            display='block'
            flexGrow={1}
            py='1px'
            color={color}
            textStyle='monoline-body'
            {...labelProps}
          >
            {children}
          </Text>
          {hotkey && (
            <Text ml={3} textStyle='detail' color='text/detail-moderate' {...keyboardShortcutProps}>
              {getHotkeyString(hotkey)}
            </Text>
          )}
          {endContent && (
            <EndContentProvider value={{ iconColor: 'icon/indicator' }}>
              <Box ml={2}>{endContent}</Box>
            </EndContentProvider>
          )}
        </Flex>
        {description && (
          <Text
            display='block'
            textStyle='detail'
            color='text/detail-moderate'
            mt={2}
            {...descriptionProps}
          >
            {description}
          </Text>
        )}
      </Box>
    </StyledBox>
  )
}

export const ActionItemPublic = ActionItemInternal as unknown as React.FC<PublicProps>

const StyledBox = styled(Box)<{ variant: Variant }>`
  cursor: pointer;
  &[aria-disabled='true'] {
    cursor: default;
  }

  :focus {
    outline: none;
    background: ${({ theme, variant }) =>
      theme.colors[variant === 'caution' ? 'bg/list-item-caution-hover' : 'bg/list-item-hover']};
  }
`

const getColors = (
  isDisabled: boolean,
  variant: string
): { bg?: ThemeColors; color: ThemeColors; iconColor: ThemeColors } => {
  if (isDisabled) {
    return {
      bg: 'bg/disabled',
      color: 'text/action-disabled',
      iconColor: 'icon/disabled',
    }
  }
  if (variant === 'caution') {
    return {
      bg: 'bg/list-item',
      color: 'text/action-caution',
      iconColor: 'icon/action-caution',
    }
  }
  return {
    bg: 'bg/list-item',
    color: 'text/action-item',
    iconColor: 'icon/action-moderate',
  }
}
